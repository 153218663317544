import Footer from "./footer"

import { Link } from "react-router-dom"
import { useContext, useState } from "react"
import { cartContext } from "../App"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const NavHeader = () => {
  const { cartProducts, changeCartProduct } = useContext(cartContext)
  return (
    <nav>
    <div className="nav-bar">
    <span className="hLogo">GSF</span>
      <div className="menu">
      <Link to="/" style={{ textDecoration: "none" }}><i className='bx bxs-home icon-style'></i><span class="navbar-menu-name">Home</span></Link>
      <Link to="/cart" style={{ textDecoration: "none" }}> {cartProducts.length > 0 && <span className="cart-count">{cartProducts.length}</span>}<i className='bx bx-cart icon-style' /><span class="navbar-menu-name">Cart</span></Link>         
      </div>
    </div>
    </nav>   
  )
}


export const CartPriceTotal = () => {

  const { cartProducts, changeCartProduct } = useContext(cartContext)

  const gstPrice = 30

  const calculateSubTotal = () => {
       let priceTotal = 0
       cartProducts.map((item,index) => {
        priceTotal = priceTotal + item.totalPrice
       } )          
       return priceTotal
   }

     return(
        <div id="cart-price-total">
          <h2 className="cart-price-heading">Cart Summary</h2>
          <div className="cart-summary-items">
             <h5>Subtotal</h5>           
             <h5>{calculateSubTotal()}</h5>
          </div>
          <div className="cart-summary-items">
             <h5>GST</h5>
             <h5>₹{gstPrice}</h5>
          </div>
          <div className="cart-summary-items">
             <h5>Delivery</h5>
             <h5>Free</h5>
          </div>
          <div className="cart-summary-items totalPrice">
             <h5>Total</h5>
             <h5>₹{calculateSubTotal() + gstPrice}</h5>
          </div>
          <button type="button" name="button" class="cart-summary-btn">Place Order</button>   
       </div>
     ) 
}

export const CartItemComponent = (props) => {
    return(
        <div class="cart-items">
          <div class="cart-img">
             <img src={props.products.img} alt="item-img" />
          </div>
        
        <div class="cart-item-info">
        <h2 className="itemName">{props.products.item}</h2>  
        <h2 className="itemQty">Qty : {props.products.quantity}</h2>         
        <h2 className="itemPrice">Price : {props.products.totalPrice}</h2> 
           <button className = "cart-button"> <Link to="/product-info" state={{ img: props.products.img, name : props.products.item, productQty : props.products.quantity, piecePrice : (props.products.totalPrice/props.products.quantity), editMode : true ,cartIndex : props.itemIndex}} className = "btn-cart-edit">Edit</Link></button>  

            <button style={{marginLeft :"10px"}}
              onClick={() => {
                props.deleteCartItem(props.itemIndex);
              }} className = "cart-button"
            > Delete</button>     
        </div>    
     </div>
    )   
}

export const CartProduct = () => {
    const { cartProducts, changeCartProduct } = useContext(cartContext)

    const RemoveFromCart = (removeIndex) => {  
      changeCartProduct([
        ...cartProducts.slice(0, removeIndex),
        ...cartProducts.slice(removeIndex + 1)
      ]);
  
      alert("Item Removed from cart");
    };

    return (
        <>  
            <NavHeader />
            <div id="cart-page">
             <Link to="/"><i className='bx bx-x-circle form-close-icon'></i></Link>
               <div className="container-style">
               {cartProducts.length <= 0 && <h3 className ="empty-cart-txt">Your Cart is Empty</h3>}
               {cartProducts.length > 0 &&
                <Container>
                  <Row>
                   <Col md={7}>
                     {cartProducts.map((product, index) => (
                    <CartItemComponent products= {product} itemIndex = {index} deleteCartItem = {RemoveFromCart}/>
                       ))  }   
                   </Col>
                   <Col md={4}> <CartPriceTotal /> </Col>
                 </Row>
                </Container>
              }   
               </div>
              </div>  
              <Footer /> 
             </> 
                )
              }