function CategorySection(props){   
  return(
    <div id="category-section">
    <div class="container cat-container">
     {props.category.map((item,index)=> (     
        <div className="category-continer" key={index} onClick = {() => props.changeCategory(index)}>        
          <img src={item.ImagePath} alt="category" />
          <h3 className="category-txt">{item.categoryName}</h3>
        </div>)
     )}
    </div>
  </div>
  )
}

export default CategorySection