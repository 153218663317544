import { Link } from "react-router-dom"

export const UserLogin = () => {


    return(        
         <div id="user-login-section">
            <Link to="/"><i className='bx bx-x-circle form-close-icon'></i></Link>
            <div className="user-login-form">
              <div className="form-logo">
                 <img src="images/ThanigaiFacts.png" alt="form-logo" />
               </div>
               <h3 className="user-login-heading">Login</h3>
               <form id="login-form" name="loginForm" action = "#" method="post">
                 <i className='bx bx-envelope form-icon-style'></i><input type="email" name="user-mail_id"  placeholder="Mail id" /><br />
                 <i className='bx bx-lock form-icon-style'></i><input type="password" name="user-password"   placeholder="New password" /><br />
                 <button type="button" name="button" onclick="verifyUser()" className="form-btn">login</button><br />                     
                 <Link to="/register" className="form-signup-link">Click here to Sign up</Link>
               </form>
             </div>
          </div>         
    )
}


export const UserRegister = () => {
    return(
      <div id="user-login-section">
         <Link to="/"><i className='bx bx-x-circle form-close-icon'></i></Link>
      <div className="user-login-form">
         <div className="form-logo">
           <img src="images/ThanigaiFacts.png" alt="form-logo" />
         </div>
         <h3 className="user-login-heading">Sign Up</h3>
         <form id="login-form" name="login-form" action = "#" method="post">
         <i className='bx bxs-user form-icon-style'> </i><input type="text" name="user-name"  placeholder="user name" /><br />
            <i className='bx bx-envelope form-icon-style'></i><input type="email" name="user-mail_id"  placeholder="Mail id" /><br />
            <i className='bx bx-phone-call form-icon-style'></i><input type="number" name="user-mobile" placeholder="Mobile Number" /><br />
            <i className='bx bx-lock form-icon-style'></i><input type="password" name="user-password_new"  placeholder="New password" /><br />
            <i className='bx bx-lock form-icon-style'></i><input type="password" name="user-password_confirm"  placeholder="Confirm password" /><br />
            <button type="button" name="button"  className="form-btn">Register</button><br />            
            <Link to="/login" className="form-signup-link">Click here to Login</Link>
         </form>
      </div>
  </div>
    )
}

