import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { useContext, useState } from "react"
import { cartContext } from "../App"
import { useNavigate } from "react-router-dom"
import { createRef } from "react"


export const ProductInfo = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {name,img,productQty,piecePrice,editMode,cartIndex} = location.state
   
    const { cartProducts, changeCartProduct } = useContext(cartContext);

    const [qty,setQty] = useState(productQty);
    const [price,setPrice] = useState(piecePrice * qty); 

    const qtyInputRef = createRef()



    const addToCart = () => {
      if(qty === 0 || price === 0){
         alert("Atleast Select One Quantity to Proceed")
         qtyInputRef.current.value = productQty
         setQty(qtyInputRef.current.value * 1);          
         setPrice(piecePrice * (qtyInputRef.current.value * 1))
      }
      else{
        const newItem = {
          item: name,
          img: img,
          quantity : qty,
          totalPrice : price      
        };
    
        if (cartProducts.length > 0) {
          changeCartProduct([...cartProducts, newItem]);
        } else {
          changeCartProduct([newItem]);
        } 
        alert("Item Added to Cart");
        navigate('/')
      }
  };

  const saveToCart = () => {
    if(qty === 0 || price === 0){
      alert("Atleast Select One Quantity to Proceed")
      qtyInputRef.current.value = productQty
      setQty(qtyInputRef.current.value * 1);          
      setPrice(piecePrice * (qtyInputRef.current.value * 1))
   }
   else{

    const newItem = {
      item: name,
      img: img,
      quantity : qty,
      totalPrice : price      
    };

      changeCartProduct([
        ...cartProducts.slice(0, cartIndex),
        newItem,
        ...cartProducts.slice(cartIndex + 1)
      ]);
      alert("Item Updated Successfully");
      navigate('/cart') 
    }
  }

  const decreaseQty = ()  => {
        if(qty-1 > 0){
          setQty(qty - 1);          
          setPrice(piecePrice * (qty - 1))
          qtyInputRef.current.value = qty -1 
        }
      }  
  
  const increaseQty = ()  => {         
          setQty(qty + 1);               
          setPrice(piecePrice * (qty + 1))
          qtyInputRef.current.value = qty + 1
      }

  const updatePrice = () => { 
    setQty((qtyInputRef.current.value * 1)); 
    setPrice(qtyInputRef.current.value * piecePrice)
  }

    return(
        <div id="product-info-section">  
        <Link to="/"><i className='bx bx-x-circle form-close-icon'></i></Link>         
             <div className="product-info-container"> 
             <h1 className="Shop-title">Ganapathi Sweets and Fruits</h1>            
             <div class="product-info-items">
               <div class="product-info-img">
                   <img src={img} alt="item-img" />
               </div>        
               <div class="product-info">
                  <h2 className="itemName">{name}</h2> 
                  <span class="qtySection"><h4 className="itemQty">Quantity : {qty}</h4><button onClick={decreaseQty}>-</button><input type="number" className = "qtyInput" defaultValue={qty} ref = {qtyInputRef} onChange = {updatePrice}/><button onClick={increaseQty}>+</button></span>
                   <h2 className="itemPrice">₹{price}</h2>
                   {!editMode ? <button className="product-info-cart-btn" onClick={addToCart}><i className='bx bx-cart btn-cart-icon' />Add to Cart</button> : <button className="btn-cart" onClick={saveToCart}><i className='bx bx-cart btn-cart-icon' />Save</button>     }                  
               </div>    
             </div>
             </div>
        </div>
    )
}