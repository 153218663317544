// import Styled from "styled-components" 
import {BrowserRouter ,Routes,Route} from "react-router-dom"
import {Home} from "./components/home"
import { UserLogin, UserRegister } from "./components/userVerification";
import { CartProduct } from "./components/cartProducts";
import { createContext } from "react";
import { useState } from "react";
import { ProductInfo } from "./components/productInfo";

export const cartContext = createContext();

function App() {
  //{ item: "Sweet", price: "100" }
  // const [cart, setCart] = useState([]);
  

  const val = {
    item : "Mango",
    img  : require('./components/images/fruits/mango.png'),
    quantity : 1,
    totalPrice : 15  
  }
 
  const [cart, setCart] = useState([val]);
  


  return (
    <div className="App">   
      <cartContext.Provider value={{ cartProducts: cart, changeCartProduct: setCart }}>
      <BrowserRouter>
        <Routes>        
          <Route path="/" element={<Home />}>
             <Route path="login" element = {<UserLogin />} />
             <Route path="register" element = {<UserRegister />} />  
             <Route path="product-info" element = {<ProductInfo />} />           
          </Route> 
          <Route path="cart" element = {<CartProduct/>} />                        
        </Routes>
      </BrowserRouter>     
      </cartContext.Provider>   
    </div>
  );
}

export default App;
