import { Outlet } from "react-router-dom";
import LandingPage from "./landingPage";
import CategorySection from "./categorySection";
import { Products} from "./products";
import { Experience } from "./ownerInfo";
import Footer from "./footer";
import {CategoryData,ProductData} from "../data"
import { useState } from "react";
// import { createContext } from "react";

// export const cartContext = createContext();

export const Home = () => {  
    const [selectedCategory,setSelectedCategory] =  useState(0);

    // const [cart, setCart] = useState([{ item: "Sweet", price: "100" }]);
    
    const changeCategory = (num) => {      
       setSelectedCategory(num)        
    }

    return(
        <div className="HomeContainer" >
          {/* <cartContext.Provider  value={{ cartProducts: cart, changeCartProduct: setCart }}> */}
          <LandingPage/>   
          <CategorySection category = {CategoryData()} changeCategory = {changeCategory}/>      
          <Products products = {ProductData()}  selectedCategoryIndex = {selectedCategory}/>
          <Experience />      
          <Footer />
          <Outlet />
          {/* </cartContext.Provider> */}
        </div>
    )
}