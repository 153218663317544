import { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/virtual';
// import {useContext} from "react"
// import { cartContext } from "../App";
import { Link } from 'react-router-dom';

/*
export const ProductItems = (props) => {
  <>
  <h3 className="item-heading">{props.product.categoryName}</h3>          
  <div className="items-container">  
   <Swiper modules={[Virtual]} className = "swiper_container" spaceBetween={10} slidesPerView={1} grabCursor={true} breakpoints={{

640: {
slidesPerView: 2,
spaceBetween: 20
},


780: {
slidesPerView: 3,
spaceBetween: 10
},

1080: {
slidesPerView: 4,
spaceBetween: 10
},
}}  virtual >  

 { props.product.Products.map((item,itemIndex) => (   
 <SwiperSlide key={item.itemName} virtualIndex={itemIndex} className= "swiper_product_container" >
    <div className="card card-style">
     <img className="card-img-top" src={item.itemImg} alt={item.itemName} style={{width:"40%",margin:"auto"}} />
     <div className="card-body">
       <h4 className="card-title cd-title">{item.itemName}</h4> 
      <p className="card-text cd-text">{item.itemDesc }</p>
      <a href="#item-section" className="btn-cart">Add to Cart</a>
     </div>
   </div>         
 </SwiperSlide> 
  ))
 }
 
 </Swiper>                
</div> 
  </>
}
*/

export const ProductItems = (props) => { 
  // const { cartProducts, changeCartProduct } = useContext(cartContext);
  // const addToCart = (index) => {
  //   const newItem = {
  //     item: props.product.Products[index].itemName,
  //     img: props.product.Products[index].itemImg,
  //     // price: props.product.Products[index].price
  //   };
  //   if (cartProducts.length > 0) {
  //     changeCartProduct([...cartProducts, newItem]);
  //   } else {
  //     changeCartProduct([newItem]);
  //   } 
  //   alert("Item Added to Cart");
  // };


  return(
    <>
    <h3 className="item-heading">{props.product.categoryName}</h3>   
    <div className="items-container">  
    <Swiper modules={[Virtual]} className = "swiper_container" spaceBetween={10} slidesPerView={1} grabCursor={true} breakpoints={{

640: {
slidesPerView: 2,
spaceBetween: 20
},


780: {
slidesPerView: 3,
spaceBetween: 10
},

1080: {
slidesPerView: 4,
spaceBetween: 10
},
}}  virtual >  
       {props.product.Products.map((item,itemIndex) => (
        <SwiperSlide key={item.itemName} virtualIndex={itemIndex} className= "swiper_product_container" >
          <div className="card card-style">
          <img className="card-img-top" src={item.itemImg} alt={item.itemName} style={{width:"40%",margin:"auto"}} />
          <div className="card-body">
            <h4 className="card-title cd-title">{item.itemName}</h4> 
            <p className="card-text cd-text">{item.itemDesc }</p>
            {/* <p className="card-text cd-text">{item.piece_price }</p> */}
            <Link to="/product-info" state={{ img: item.itemImg, name : item.itemName, productQty : 1, piecePrice : item.piece_price,editMode : false }} className="btn-cart" >Check Out</Link>
            {/* <Link to="/product-info" className="btn-cart" >Check Out</Link> */}
            {/* <button className="btn-cart" onClick={()=>{addToCart(itemIndex)}} ><i className='bx bx-cart btn-cart-icon' />Add to Cart</button> */}
           </div>
        </div> 
      </SwiperSlide>
       ))
       } 
       </Swiper>   
    </div>  
    </>
  )
}


export const Products = (props) =>{

 return(
    <div id="item-section">    {
       props.products.map((product,index)=>(      
      <div id={ product.categoryName }> 
         {(props.selectedCategoryIndex !== 0) ?(((props.selectedCategoryIndex - 1) === index) ? (<ProductItems product = {product}/>) : ("")) : (<ProductItems product = {product}/>) }         
      </div> 
    )) 
   }          
   </div>
   )     
 }






// <div id="item-section">
//     {props.products.map((product,index)=>(      
//       <div id={ product.categoryName }>        
//        {(props.selectedCategoryIndex != 0) ?(
//         ((props.selectedCategoryIndex - 1) === index) ? (
//           <>
//           <h3 className="item-heading">{product.categoryName}</h3>          
//           <div className="items-container">  
//            <Swiper modules={[Virtual]} className = "swiper_container" spaceBetween={10} slidesPerView={1} grabCursor={true} breakpoints={{
    
//     640: {
//       slidesPerView: 2,
//       spaceBetween: 20
//     },


//     780: {
//       slidesPerView: 3,
//       spaceBetween: 10
//     },

//     1080: {
//       slidesPerView: 4,
//       spaceBetween: 10
//     },
//   }}  virtual >  
      
//          { product.Products.map((item,itemIndex) => (   
//          <SwiperSlide key={item.itemName} virtualIndex={itemIndex} className= "swiper_product_container" >
//             <div className="card card-style">
//              <img className="card-img-top" src={item.itemImg} alt={item.itemName} style={{width:"40%",margin:"auto"}} />
//              <div className="card-body">
//                <h4 className="card-title cd-title">{item.itemName}</h4> 
//               <p className="card-text cd-text">{item.itemDesc }</p>
//               <a href="#item-section" className="btn-cart">Add to Cart</a>
//              </div>
//            </div>         
//          </SwiperSlide> 
//           ))
//          }
         
//          </Swiper>                
//         </div> 
//           </>
//         ) : ("")) : (
         
//           <>
//           <h3 className="item-heading">{product.categoryName}</h3>          
//           <div className="items-container">  
//            <Swiper modules={[Virtual]} className = "swiper_container" spaceBetween={10} slidesPerView={1} grabCursor={true} breakpoints={{
    
//     640: {
//       slidesPerView: 2,
//       spaceBetween: 20
//     },


//     780: {
//       slidesPerView: 3,
//       spaceBetween: 10
//     },

//     1080: {
//       slidesPerView: 4,
//       spaceBetween: 10
//     },
//   }}  virtual >  
      
//          { product.Products.map((item,itemIndex) => (   
//          <SwiperSlide key={item.itemName} virtualIndex={itemIndex} className= "swiper_product_container" >
//             <div className="card card-style">
//              <img className="card-img-top" src={item.itemImg} alt={item.itemName} style={{width:"40%",margin:"auto"}} />
//              <div className="card-body">
//                <h4 className="card-title cd-title">{item.itemName}</h4> 
//               <p className="card-text cd-text">{item.itemDesc }</p>
//               <a href="#item-section" className="btn-cart">Add to Cart</a>
//              </div>
//            </div>         
//          </SwiperSlide> 
//           ))
//          }
         
//          </Swiper>                
//         </div> 
//           </>          
//         )
//         }
//      </div>  
//      ))
//      }
//   </div>

