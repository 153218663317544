import { Link, useSearchParams } from "react-router-dom"
import {useContext, useState} from "react"
import { cartContext } from "../App"; 

function NavBar(){
    const { cartProducts} = useContext(cartContext);
    const [showSearchBox , setShowSearchBox] = useState(false)

    const Searchbox = () => {      
      setShowSearchBox(!showSearchBox)
    }

    return(
        <nav>
        <div className="nav-bar">
        <span className="hLogo">GSF</span>
          <div className="menu">
            <span id="search-icon" onClick={Searchbox}><i className='bx bx-search-alt-2 icon-style'></i></span>
            <form className={showSearchBox ? "search-form-active" : "search-form"} action="#" method="post" id="Search-Form">
            {/* <form className= "search-form" action="#" method="post" id="Search-Form"> */}
               <input type="text" name="search-input" placeholder="Search" />
               <label for="search-input"><i className='bx bx-search-alt-2'></i></label>
            </form>
            <Link to="login" style={{ textDecoration: "none" }}><i className='bx bxs-user icon-style'></i><span class="navbar-menu-name">Account</span></Link>
            <Link to="/cart" style={{ textDecoration: "none" }}> {cartProducts.length > 0 && <span className="cart-count">{cartProducts.length}</span>}<i className='bx bx-cart icon-style' /><span class="navbar-menu-name">Cart</span></Link>         
          </div>
        </div>
        </nav>                 
    )
}

export default NavBar