export const CategoryData = () => {
    const categoryList = [{
        "categoryName" : "All",
        "ImagePath"    : require('./components/images/category/buiscuit.png'),
     },
     {
       "categoryName" : "Sweets",
       "ImagePath"    : require('./components/images/category/sweet.png'),    
     },
     {
     "categoryName" : "Fruits",
     "ImagePath"    : require('./components/images/category/fruit.png'),    
     },
     {
       "categoryName" : "Buiscuits",
       "ImagePath"    : require('./components/images/category/buiscuit.png'),    
     },
     {
       "categoryName" : "Soft Drinks",
       "ImagePath"    : require('./components/images/category/soft_drinks.png'),    
     },
    ]  
    return categoryList
    
}

export const ProductData = () => {
  const productItems = [{"categoryName" : "Sweets",
                         "Products" : [
    {"itemName": "laddu",
     "itemDesc": "Some example text some example text. about the item ",
     "itemImg":   require('./components/images/sweets/laddu.png'),
     "piece_price" : 10 
    },

    {"itemName": "Jilabi",
     "itemDesc": "Some example text some example text. about the item ",
     "itemImg":  require('./components/images/sweets/jilabi.png'),
     "piece_price" : 5 
    }, 

    {"itemName": "Mysore Pak",
     "itemDesc": "Some example text some example text. about the item ",
     "itemImg":  require('./components/images/sweets/mysore.png'),
     "piece_price" : 10  
    },

    {"itemName": "Jilabi",
     "itemDesc": "Some example text some example text. about the item ",
     "itemImg": require('./components/images/sweets/jilabi.png'),
     "piece_price" : 5   
    },

    {"itemName": "Mysore Pak",
     "itemDesc": "Some example text some example text. about the item ",
     "itemImg": require('./components/images/sweets/mysore.png'),
     "piece_price" : 10    
    }
]},

 {   "categoryName" : "Fruits",
       "Products" : [
        {"itemName": "Orange",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/fruits/orange.png'),
         "piece_price" : 20     
        },

        {"itemName": "Apple",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/fruits/apple.png') ,
         "piece_price" : 25     
        },

        {"itemName": "Mango",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/fruits/mango.png'),
         "piece_price" : 15      
         },

        {"itemName": "Grapes",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/fruits/grapes.png'),
         "piece_price" : 30     
         },

        {"itemName": "Banana",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/fruits/banana.png'),
         "piece_price" : 10     
         },
    ]},
 {
   "categoryName" : "Buiscuits",
   "Products" : [
        {"itemName": "Dark Fantasy",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/biscuits/dark_fantasy.png'),
         "piece_price" : 40     
         },

        {"itemName": "Good Day",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/biscuits/good_day.png'),
         "piece_price" : 20     
         },

        {"itemName": "Milk Bikis",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/biscuits/Milk_bikis.png'),
         "piece_price" : 20     
         },

        {"itemName": "Oreo",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/biscuits/oreo.png'),
         "piece_price" : 10     
         },

        {"itemName": "Unibic",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/biscuits/unibic.png'),
         "piece_price" : 20     
         },
    ]
  },
    { "categoryName" : "Soft Drinks",
      "Products" : [
        {"itemName": "Pepsi",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/soft_drinks/pepsi.png'),
         "piece_price" : 60    
         },

        {"itemName": "Fanta",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/soft_drinks/fanta.png'),
         "piece_price" : 50     
         },

        {"itemName": "Limca",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/soft_drinks/limca.png'),
         "piece_price" : 30     
         },

        {"itemName": "Sprite",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/soft_drinks/sprite.png'),
         "piece_price" : 40     
         },

        {"itemName": "Maaza",
         "itemDesc": "Some example text some example text. about the item ",
         "itemImg": require('./components/images/soft_drinks/maaza.png'),
         "piece_price" : 30     
         },
     ]
   }
 ]
 return productItems
}

 


