import NavBar from "./navBar"
import sweetBanner from "./images/sweets_banner.jpg"
import fruitBanner from "./images/fruits_banner.jpg"
import drinksBanner from "./images/soft_drink_banner.jpg"
import { useEffect,useState } from "react"

function LandingPage(props){ 
  const [bannerImgIndex,setBannerImgIndex] = useState(0)
  const imgArr = [sweetBanner,fruitBanner,drinksBanner]
  const getIndicatorColor = (val) => bannerImgIndex % 3 === val ? "red" : "white"


  useEffect(()=>{
    const interval = setInterval(()=>{setBannerImgIndex((bannerImgIndex)=>bannerImgIndex + 1)},2000);
    return ()=> clearInterval(interval)
  },[])

    return(
        <div id="home-container">
            <div id="landing-page">
               <NavBar />                 
               <div className="header-carosol" id="carosol-container">
                 <div className="custom-caption">
                   <h1>Ganapathi Sweets & Fruits</h1>
                   <h3>Eat <span className="caption-style">Fresh</span> Stay <span className="caption-style">Healthy</span></h3>
                 </div>
                 <img alt="banner" className="carosol-img" id="carosol-img"  src ={imgArr[bannerImgIndex % 3]}/>  
                 {/* {imgArr.map((imgIndicator,index)=>{<span className="img-indicator-test">{index}</span>})} */}
                 <span className="img-indicator" id="ind-one" style={{ backgroundColor: getIndicatorColor(0) }}></span>
                 <span className="img-indicator" id="ind-two" style={{ backgroundColor: getIndicatorColor(1) }}></span>
                 <span className="img-indicator" id="ind-three" style={{ backgroundColor: getIndicatorColor(2) }}></span>                
              </div>               
             </div>             
        </div>
    )
}

export default LandingPage