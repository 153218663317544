export const ContactUs = () => {
    return(
        <div id="contact-us">
          <h3 className="section-heading">Contact Us</h3>
          <div className="mail-enclosing"><i className='bx bx-envelope mail-icon'></i><a href="mailto:ganapathisweetsandfruits@gmail.com" className="mail-link">ganapathisweetsandfruits@gmail.com</a></div>
       </div>
    )
   
}

export const LocateUs = () => {
    return(
        <div id="locate-us">
         <div className="row">
            <h3 className="section-heading">Locate Us</h3>
            <div className="col-md-6 col-sm-12">
               <iframe
                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3883.575154549762!2d80.01212661404631!3d13.251957412251699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5286ac81013073%3A0x7f6633d7ab58f766!2sGanapathi%20sweets%20and%20fruits!5e0!3m2!1sen!2sin!4v1674884080777!5m2!1sen!2sin"
                 width="400" height="300" title ="shop-location" style={{ border:0  }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="map-img">
               </iframe>
            </div>
            <div className="col-md-6 col-sm-12 shop-address">
               <h3 className="locate-header">Ganapathi Sweet & Fruits</h3>
               <p className="locate-txt">Nehru Bazaar st,<br />Near to Vengal Police Station,<br />Vengal - 601103,<br />
                   Thiruvallur District,<br />Tamil Nadu.
               </p>
            </div>
         </div>
      </div>
    )
   
}

export const Experience = () => {
  return(
    <div class="container contact-section">
    <div id="experience-section">
      <h3 className="section-heading">About Us</h3>
      <div className="row">
        <div className="col-md-6 col-sm-12">          
          <img src={require('./images/shop_img.jpeg')} alt="shop-img" />
        </div>
        <div className="col-md-6 col-sm-12 exp-data">
          <h1 className="exp-header">15+ Years of Experience</h1>
          <p className="exp-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet malesuada turpis. Vestibulum at facilisis dui, eu dignissim leo. Curabitur accumsan rhoncus odio, ut porttitor erat sagittis ut. Donec ullamcorper
            convallis lectus et congue. Sed lobortis faucibus orci sed consectetur. Nam blandit a justo ut cursus. Pellentesque et tempor turpis, quis laoreet arcu.
            Vestibulum id justo tristique, facilisis libero a, condimentum tortor. Quisque mollis, turpis et ultrices ultrices, justo eros tempus eros, eu consectetur lectus est vitae risus. Nam dui nisi, tempor sit amet enim et, rutrum auctor ante.
            Quisque vehicula lectus magna, aliquet molestie tortor gravida vitae. Sed sagittis quam non enim rhoncus dapibus.
            Integer efficitur risus erat, nec eleifend eros rhoncus et. Ut imperdiet tellus nec tempor ultricies.</p>
        </div>
      </div>
    </div>
    <LocateUs />
    <ContactUs />
    </div>
  )
}
